'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
import { height } from './view/_height';
import { scroll } from './view/_scroll';
import { header } from './view/_header';
import { accordion } from './view/_accordion';
import { faq } from './view/_faq';
import { application } from './view/_application';
import { anchorMore } from './view/_anchorMore';
import { bg } from './view/_bg';
import { kv } from './view/_kv';
import { contact } from './view/_contact';
import { news } from './view/_news';

// import { movie } from './view/_movie';
