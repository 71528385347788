if($('#agreeBtn').length){
	var btn = $('#agreeBtn');

	$(btn).addClass('-disabled');

	$('#agree').on('click', function() {
		if ( $(this).prop('checked') == false ) {
			$(btn).addClass('-disabled');
		} else {
			$(btn).removeClass('-disabled');
		}
	});
}
