if($('[data-scroll]').length){
	let _EffectH = 100;
	$(window).on('scroll load', function() {
		let _scTop = $(this).scrollTop();
		let _scBottom = _scTop + $(this).height();
		let _effectPos = _scBottom - _EffectH;
		$('[data-scroll]').each( function() {
			let _thisPos = $(this).offset().top;
			if ( _thisPos < _effectPos ) {
				$.when(
					$(this).addClass('is-show')
				).done(function() {
					$(this).delay(500).queue(function(){
						$(this).addClass('is-done')
					})
				});
			}
		});
	});
}

// if($('[data-splitWord]').length){
// 	// add span
// 	let _elem = $('[data-splitWord]');
// 	$(window).on('load', function () {
// 		_elem.each(function () {
// 			let text = $(this).text();
// 			let textBox = '';
// 			text.split('').forEach(function (t, i) {
// 				if (t !== ' ') {
// 					if (i < 10) {
// 						textBox += '<span class="js-split">' + t + '</span>';
// 					} else {
// 						var n = i / 10;
// 						textBox += '<span class="js-split">' + t + '</span>';
// 					}
// 				} else {
// 					textBox += t;
// 				}
// 			});
// 			$(this).html(textBox);
// 		});
// 	});
// }

if($('[data-splitWordColor]').length){
	// add span
	let _elem = $('[data-splitWordColor]');
	$(window).on('load', function () {
		_elem.each(function () {
			let text = $(this).text();
			let textBox = '';
			text.split('').forEach(function (t, i) {
				if (t !== ' ') {
					if (i < 10) {
						textBox += '<span class="js-split">' + t + '</span>';
					} else {
						var n = i / 10;
						textBox += '<span class="js-split">' + t + '</span>';
					}
				} else {
					textBox += t;
				}
			});
			$(this).html(textBox);
		});
	});
}

$('a[href^="#"]').click(function(){
	let speed = 500;
	let href= $(this).attr("href");
	let target = $(href == "#" || href == "" ? 'html' : href);
	let position = target.offset().top;
	$("html, body").animate({scrollTop:position}, speed, "swing");
	return false;
});

