if($('#js-newsList').length){

	let _list = $('#js-newsList')
	let _post = [];
	let _themePath = $('.l-header__logo img').attr('src').replace('/assets/img/common/logo.svg', '');

	$.ajax({
		type: 'GET',
		dataType: 'json',
		// url: '/wp-json/wp/v2/posts?categories_exclude=1&status=publish&_fields=title,link,cat_info,date'
		url: '/wp-json/wp/v2/posts'
	}).done(function (response) {
		_post = [];
		_post = response;
		for (let i = 0; i < 5; i++) {
			let _title = _post[i].title.rendered;
			let _link = _post[i].link;
			let _dateTime = new Date(_post[i].date);
			let _year = _dateTime.getFullYear();
			let _month = _dateTime.getMonth() + 1;
			let _day = _dateTime.getDate();
			let _date = _year + '-' + _month + '-' + _day;
			let _dateTxt = _year + '.' + _month + '.' + _day;
			let _category = _post[i].cat_info;
			let _categorySlug = [];
			$.each(_category,function(index,value){
				_categorySlug.push(value.category_nicename);
			})
			let _categoryName = _categorySlug[0].toUpperCase();
			let _tag = _post[i].tags_info;
			let _tagName = [];
			$.each(_tag,function(index,value){
				_tagName.push(value.name);
			})
			if(_tagName.length == 0){
				_tagName.push('');
			}
			let _thumbnail = _post[i].thumbnail.url;
			if(typeof _thumbnail === 'undefined'){
				_thumbnail = `${_themePath}/assets/img/common/logo.svg`;
			}
			let _item = `<li class="l-newsList__item p-homeNews__listItem" data-category="${_categorySlug[0]}">
				<a class="l-newsList__link p-homeNews__listLink" href="${_link}">
					<span class="l-newsList__icon p-homeNews__listIcon">
						<img src="${_thumbnail}" alt="${_title}" fetchpriority="low">
					</span>
					<p class="l-newsList__header p-homeNews__listHeader">
						<span class="c-tag l-newsList__tag p-homeNews__listTag -${_categorySlug[0]}">${_categoryName}</span>
						<span class="c-tag l-newsList__tag p-homeNews__listTag -plain">${_tagName[0]}</span>
						<time class="l-newsList__date p-homeNews__listDate" datetime="${_date}">${_dateTxt}</time>
					</p>
					<p class="l-newsList__title p-homeNews__listTitle">${_title}</p>
				</a>
			</li>`
			_list.append(_item);
		}
	}).fail(function (XMLHttpRequest, textStatus, errorThrown) {
		// console.log('XMLHttpRequest : ' + XMLHttpRequest.status);
		// console.log('textStatus     : ' + textStatus);
		// console.log('errorThrown    : ' + errorThrown.message);
	});

	let _btn = $('.p-homeNews__categoryLink');
	_btn.click(function(){
		if($(this).hasClass('is-active')){
			return;
		} else {
			_list.empty();
			let _categorySlug = $(this).attr('data-slug');
			let _param = '';
			if(_categorySlug != ''){
				_param = 'categories='+_categorySlug;
			}else{
				_param = 'categories_exclude=1';
			}
			_btn.removeClass('is-active');
			$(this).addClass('is-active');
			console.log(_param)
			$.ajax({
				type: 'GET',
				dataType: 'json',
				url: '/wp-json/wp/v2/posts?'+_param+'&_fields=title,link,cat_info,tags_info,date,thumbnail'
			}).done(function (response) {
				_post = [];
				_post = response;
				for (let i = 0; i < 5; i++) {
					let _title = _post[i].title.rendered;
					let _link = _post[i].link;
					let _dateTime = new Date(_post[i].date);
					let _year = _dateTime.getFullYear();
					let _month = _dateTime.getMonth() + 1;
					let _day = _dateTime.getDate();
					let _date = _year + '-' + _month + '-' + _day;
					let _dateTxt = _year + '.' + _month + '.' + _day;
					let _category = _post[i].cat_info;
					let _categorySlug = [];
					$.each(_category,function(index,value){
						_categorySlug.push(value.category_nicename);
					})
					let _categoryName = _categorySlug[0].toUpperCase();
					let _tag = _post[i].tags_info;
					let _tagName = [];
					$.each(_tag,function(index,value){
						_tagName.push(value.name);
					})
					if(_tagName.length == 0){
						_tagName.push('');
					}
					// let _thumbnail = _post[i].thumbnail.url;
					// if(typeof _thumbnail === 'undefined'){
						_thumbnail = `${_themePath}/assets/img/common/logo.svg`;
					// }
					let _item = `<li class="l-newsList__item p-homeNews__listItem" data-category="${_categorySlug[0]}">
						<a class="l-newsList__link p-homeNews__listLink" href="${_link}">
							<span class="l-newsList__icon p-homeNews__listIcon">
								<img src="${_thumbnail}" alt="${_title}" fetchpriority="low">
							</span>
							<p class="l-newsList__header p-homeNews__listHeader">
								<span class="c-tag l-newsList__tag p-homeNews__listTag -${_categorySlug[0]}">${_categoryName}</span>
								<span class="c-tag l-newsList__tag p-homeNews__listTag -plain">${_tagName[0]}</span>
								<time class="l-newsList__date p-homeNews__listDate" datetime="${_date}">${_dateTxt}</time>
							</p>
							<p class="l-newsList__title p-homeNews__listTitle">${_title}</p>
						</a>
					</li>`
					_list.append(_item);
				}
			}).fail(function (XMLHttpRequest, textStatus, errorThrown) {
				console.log('XMLHttpRequest : ' + XMLHttpRequest.status);
				console.log('textStatus     : ' + textStatus);
				console.log('errorThrown    : ' + errorThrown.message);
			});
		}
	})

}
