const _header = $('#js-header');
const _menuBtn = $('#js-hamburger');
const _navigation = $('#js-navigation');
const _navTrigger = $('.js-navHasChild');
const _navItem = $('.l-navigation__item, .l-navigation__contact, .l-navigation__recruit');
const _html = $('html');

_menuBtn.click(function() {
	_header.toggleClass('is-open');
	_menuBtn.toggleClass('is-open');
	_navigation.toggleClass('is-open');
	_html.toggleClass('is-fixed');
});

// animation
var _timer = '';
var _width = '';
$(window).on('load resize', function(){
	_width = window.innerWidth;
	if (_timer) {
		clearTimeout(_timer);
	}
	_timer = setTimeout(function(){
		if(!$('html').hasClass('is-fixed')){
			let _pos = 0;
			function ScrollAnime() {
				var _elemTop = $('.l-main').offset().top;
				var _scroll = $(window).scrollTop();
				var _headerHeight = _header.outerHeight(true);
				if(_scroll > _headerHeight) {
					_header.addClass('is-animated');
					if(_header.hasClass('is-open')){
						_header.removeClass('is-open');
						_menuBtn.removeClass('is-open');
						_navigation.removeClass('is-open');
					}
				}else{
					_header.removeClass('is-animated');
				}
				_pos = _scroll;
			}
			$(window).scroll(function(){
				ScrollAnime();
			})
		}
	}, 1000);
	if (_width >= 1024) {
		$('.l-navigation__subList').removeClass('is-open');
		_navTrigger.removeClass('is-open');
	}else{
		_navTrigger.click(function() {
			let _e = $(this);
			_e.toggleClass('is-open');
			_e.next().toggleClass('is-open');
		});
	}
});
