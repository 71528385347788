if($('.js-kvSlider-img').length){
	let autoplay = true;
	let kvImgSwiper = new Swiper('.js-kvSlider-img', {
		loop: true,
		speed: 500,
		centeredSlides: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable:true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
	let kvHeadingSwiper = new Swiper('.js-kvSlider-heading', {
		loop: true,
		speed: 500,
		centeredSlides: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable:true,
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
	});
	$('.swiper-button').on('click', function(){
		kvImgSwiper.autoplay.stop();
		kvHeadingSwiper.autoplay.stop();
		autoplay = false;
		if(autoplay){
			kvImgSwiper.autoplay.start();
			kvHeadingSwiper.autoplay.start();
			autoplay = true;
		}
	});
}
